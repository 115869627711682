var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"historyTable"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('History.title'))+" ")]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('center',[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-clipboard-check ")]),_c('p',[_vm._v(_vm._s(_vm.$t('urlCopied')))]),_c('v-text-field',{attrs:{"readonly":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","depressed":"","color":"#43B581"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('OK'))+" ")])],1)],1)],1),_c('div',{staticClass:"history-table__btns"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-file-input',{attrs:{"hide-input":"","accept":"application/json","prepend-icon":"mdi-download-outline"},on:{"change":_vm.importSave}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('History.importGeoSave')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.exportSave}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('History.exportGeoSave')))])])],1),_c('v-text-field',{attrs:{"label":_vm.$t('History.search'),"append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"id":"history-table","calculate-widths":"","search":_vm.search,"headers":_vm.headers.filter(function (h) { return !h.hide; }),"items":_vm.items,"show-expand":"","single-expand":"","sort-by":['dateString'],"sort-desc":[true],"item-key":"id","custom-sort":_vm.customSort,"expanded":_vm.items.length > 0 ? [_vm.items[_vm.items.length - 1]] : []},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.share(item)}}},[_vm._v(" mdi-share ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"item",attrs:{"colspan":headers.length}},[(item.multiplayer)?_c('div',{staticClass:"item_time_multi"},_vm._l((_vm.playersNames(
                            item.rounds
                        )),function(playerName,index){return _c('HistoryTimeDetail',{key:"HistoryTimeDetail" + playerName,staticClass:"item__times",attrs:{"rounds":_vm.roundsPlayer(item.rounds, playerName),"playerName":playerName,"index":index}})}),1):_c('div',[_c('HistoryTimeDetail',{staticClass:"item__times",attrs:{"rounds":item.rounds}})],1),(item.gameMode === _vm.$t('modes.country'))?_c('HistoryMapCountry',{attrs:{"item":item}}):_c('HistoryMapClassic',{attrs:{"item":item}})],1)]}}],null,true)}),_c('v-btn',{staticClass:"btn-export",attrs:{"color":"primary"},on:{"click":_vm.exportCsv}},[_vm._v(" "+_vm._s(_vm.$t('History.exportCSV'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }