var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-countries",attrs:{"id":"map"}},[_c('div',{staticClass:"map-content"},[(_vm.polygonSelect || _vm.countryRandom)?_c('div',{staticClass:"map-label",attrs:{"title":_vm.countryName}},[_c('FlagIcon',{attrs:{"size":"big","rounded":"","iso-name":_vm.countryRandom ||
                        _vm.polygonSelect
                            .getFeatureById('feature')
                            .getProperty('iso_a2')}}),_c('span',{staticClass:"map-label__country-name",class:{
                    beige: !_vm.countryRandom,
                    green: !!_vm.countryRandom,
                    'white--text': !!_vm.countryRandom,
                }},[_vm._v(_vm._s(this.countryName))])],1):_vm._e(),_c('GmapMap',{ref:"mapRef",attrs:{"id":"mapCountries","center":{ lat: 37.86926, lng: -122.254811 },"zoom":1,"map-type-id":"roadmap","options":{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
            }}})],1),(this.infoWindowDatas.length > 0)?_c('div',{staticClass:"result-panel"},_vm._l((this.infoWindowDatas),function(info){return _c('div',{key:info.playerName,staticClass:"result-panel__item"},[_c('FlagIcon',{attrs:{"iso-name":info.country}}),_c('span',[_vm._v(_vm._s(info.playerName))])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }